import { Button } from 'app/components/button/common-button';
import Dialog from 'app/components/dialog';
import DialogTitle from 'app/components/dialog/dialog-titel';
import { Text, useSx, View } from 'dripsy';
import React, { useState } from 'react';

function ViewItems({ title, list, icon, label }) {
  const sx = useSx();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <View>
      <Button
        variantType="text"
        textColor="black"
        style={sx({
          margin: 0,
          minWidth: 100,
          paddingLeft: 0,
          paddingTop: 0,
          paddingBottom: 0,
          whiteSpace: 'normal',
          wordWrap: 'break-word',
          width: 250,
        })}
        contentStyle={{
          flexDirection: 'row',
          alignItems: 'flex-start',
          flex: 1,
          justifyContent: 'flex-start',
        }}
        startIconStyle={{ marginRight: 0 }}
        startIcon={icon}
        onPress={() => setOpen(true)}
      >
        <Text numberOfLines={3} >{label}</Text>
      </Button>
      {list.length ? <Dialog
        modalVisible={open}
        onCloseModal={handleClose}
        styles={sx({
          minHeight: 300,
          width: [360, 400, 500],
          padding: [2, 3],
          maxWidth: 500,
          maxHeight: 600,
        })}
      >
        <DialogTitle onClose={handleClose}>{title}</DialogTitle>
        <View style={{ flex: 1, flexDirection: 'column', display: 'flex' }}>
          {list.map((item, index) => {
            return (
              <Text key={index} style={{ marginBottom: 8 }}>
                {index + 1}. {item?.name}
              </Text>
            );
          })}
        </View>
      </Dialog>:<></>}
    </View>
  );
}

export default ViewItems;
