import { makeUseAxiosHook } from 'app/redux/store';
import { FlatList, Pressable, styled, useSx, View } from 'dripsy';
import React, { useEffect, useRef, useState } from 'react';
// import { useRouter } from 'solito/router';
// import { useCurrentRoute } from 'app/redux/hooks/use-current-route';
import SubscriptionCard from './subscription-card';
// import Tabs from 'app/components/tabs';
// import { MaterialIcons, Octicons } from '@expo/vector-icons';
// import Tooltip from 'app/components/tooltip';
import { useToast } from 'app/redux/hooks/use-toast';
import { Empty } from 'app/components/Empty';
import { Icon } from 'app/components/icon/icon';
import { Gradient } from 'dripsy/gradient';
import { useDim } from 'app/redux/hooks/useDim';
import { createParam } from 'solito';
import { I18Text } from 'app/provider/i18-provider';

export const IconButtonLeft = styled(Pressable)({
    height: '100%',
    position: 'absolute',
    zIndex: 1,
    justifyContent: 'center',
});

export const ScrollLeftIcon = styled(Icon)({
    borderRadius: 35,
    height: 60,
    width: 50,
    alignItems: 'center',
    justifyContent: 'center',
});

export const ScrollRightIcon = styled(Icon)({
    borderRadius: 35,
    height: 60,
    width: 50,
    alignItems: 'center',
    justifyContent: 'center',
    transform: [{ rotate: '180deg' }],
});

export const IconButtonRight = styled(Pressable)({
    height: '100%',
    position: 'absolute',
    zIndex: 1,
    right: 0,
    justifyContent: 'center',
});

const { useParams } = createParam<{
    productId: string;
    subscriptionId:string;
  }>();

function SubscriptionPackages({
    user,
    preference,
    ref = null,
    continueUrl,
    containerWidth=1260,
    homepage=false,
    subscriptions=[]
}: {
    user: any;
    preference: any;
    ref?: any;
    continueUrl?: string;
    containerWidth?:string | number;
    homepage?: boolean,
    subscriptions?:any
}) {
    const sendMessage = useToast();
    // const currentRoute = useCurrentRoute();
    const sx = useSx();
    // const { replace, push } = useRouter();
    const { params } = useParams();
    const refCarousel = useRef<any>();
    const [progressValue, setProgressValue] = React.useState(0);
    const [contentOffset, setContentOffset] = useState<number>(0);
    const [reachedEnd, setReachedEnd] = useState<boolean>(false);

    const { width } = useDim();

    const [subscriptionList, setSubscriptionList] = useState<any>({
        data: [],
    });
    // const [currentTab, setCurrentTab] = useState(0);    
   const { productId,subscriptionId }= params;
    const [{ data, loading }] = makeUseAxiosHook('clientbilling'
    ,{
        autoCancel:false
    }
    )({
        url: `/api/v1/subscriptions?active=true&deleted=false&$limit=20000${productId?`&competitiveExams._id=${productId}`:subscriptionId ?`&_id=${subscriptionId}`:''}`,
    });

    useEffect(() => {
        setSubscriptionList({ data:data?.data });
    }, [data, user]);

    const [
        {
            data: subscriptionData,
            loading: subscriptionLoading,
            error: subscriptionError,
        },
        getActiveSubscription,
    ] = makeUseAxiosHook('sso', {
        manual: true,
        autoCancel:false
    })({
        url: 'my-active-subscription',
        method: 'GET',
    });

    useEffect(() => {
        if (user?._id) {
            getActiveSubscription({
                params: {
                    user: user._id,
                    '$sort[_id]': -1,
                    featureType: 'prime',
                },
            });
        }
    }, [user]);

    useEffect(() => {
        if (subscriptionError && !subscriptionLoading) {
            sendMessage({
                message:
                    subscriptionError?.response?.data?.message ||
                    "Couldn't get my subscription.",
                type: 'error',
            });
        }
    }, [subscriptionData, subscriptionError]);

    //   const handleChangeTab = (id) => {
    //     setCurrentTab(id);
    //   };

    //   const daysLeft = () => {
    //     const allAccessPassObj = subscriptionData?.data?.find(
    //       (each) => each?.featureDetails?.allAccess
    //     );
    //     const currentTime: any = new Date().getTime();
    //     const diff = new Date(allAccessPassObj?.expiryDate).getTime() - currentTime;
    //     return (diff / (1000 * 60 * 60 * 24)).toFixed(0);
    //   };

    const scrollLeft = () => {
        refCarousel.current?.scrollToOffset({
            offset: contentOffset - 320,
            animated: true,
        });
        setReachedEnd(false);
    };

    const scrollRight = () => {
        refCarousel.current?.scrollToOffset({
            offset: contentOffset + 320,
            animated: true,
        });
    };

    useEffect(() => {
        if (( subscriptionList?.data?.length) || contentOffset === 0) {
            setReachedEnd(subscriptionList?.data?.length * 300 < 930);
        }
    }, [width, subscriptionList?.data?.length]);
    return (
        <View
            style={[
                sx({
                    display: 'flex',
                    flexDirection: 'row',
                    width: ['100%', '100%', '100%', '100%', containerWidth],
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }),
            ]}
            ref={ref}
        >
            <View
                style={[
                    sx({
                        flex: [3, 2, 2, 3, 3],
                    }),
                ]}
            >
                {/* <View style={{ alignItems: 'center' }}>
          {courseList?.data?.length ? (
            <Tabs
              tabList={courseList.data}
              tabRowStyle={sx({
                borderStyle: 'solid',
                borderWidth: 2,
                borderBottomWidth: 2,
                borderColor: '$primary',
                borderBottomColor: '$primary',
                borderRadius: 8,
              })}
              tabStyles={{ alignItems: 'center' }}
              activeHeaderStyle={[
                sx({
                  backgroundColor: '$primary',
                  color: 'white',
                  margin: 1,
                  borderRadius: 6,
                }),
              ]}
              headerTextStyle={{ whiteSpace: 'noWrap' }}
              value={currentTab}
              onChange={(courseId) => handleChangeTab(courseId)}
              activeColor={''}
              showBottomBorder={false}
              showsHorizontalScrollIndicator={false}
            />
          ) : (
            <></>
          )}
        </View> */}

                <View style={{ display: 'flex', justifyContent: 'center' }}>
                    {contentOffset > 0 ? (
                        <IconButtonLeft
                            disabled={contentOffset === 0}
                            onPress={() => scrollLeft()}
                            style={[
                                sx({
                                    width: [0, 30],
                                    display: ['none', 'flex'],
                                    height: [55, 55, 65, 50],
                                }),
                            ]}
                        >
                            <Gradient
                                start={{ x: 0, y: 1 }}
                                end={{ x: 1, y: 1 }}
                                colors={['#657c97', '#99999910']}
                                style={sx({
                                    flex: 1,
                                    padding: [0, 1],
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: 35,
                                    borderRadius: 5,
                                })}
                            >
                                <ScrollLeftIcon
                                    name="leftIcon"
                                    fill={['white']}
                                    style={[sx({ width: [0, 30], display: ['none', 'flex'] })]}
                                />
                            </Gradient>
                        </IconButtonLeft>
                    ) : (
                        <></>
                    )}
                    <FlatList
                        ref={refCarousel}
                        horizontal
                        scrollEnabled
                        data={homepage ? subscriptions: subscriptionList.data}
                        onScroll={(e) => {
                            setContentOffset(() => {
                                if (e?.nativeEvent?.contentOffset?.x < contentOffset) {
                                    setReachedEnd(false);
                                }
                                return e?.nativeEvent?.contentOffset?.x;
                            });
                        }}
                        contentContainerStyle={sx({
                            justifyContent: width > 768 ? 'center' : null,
                            display: 'flex',
                            minHeight: 540,
                        })}
                        renderItem={({ item, index }:{ item:any, index:number }) => (
                            <SubscriptionCard
                                key={`${index}`}
                                features={item}
                                index={index}
                                active={true}
                                continueUrl={continueUrl || `/client-checkout/subscription/${item?._id}`}
                                preference={preference}
                                setProgressValue={setProgressValue}
                                currentActive={index === progressValue}
                                user={user}
                            />
                        )}
                        onEndReachedThreshold={0.1}
                        onEndReached={({ distanceFromEnd }) => {
                            distanceFromEnd >= 0 && setReachedEnd(true);
                        }}
                        ListEmptyComponent={() => (
                            <View
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flex: 1,
                                }}
                            >
                                {!loading && !data?.data?.length ? (
                                    <Empty />
                                ) : (
                                    loading && <I18Text>Loading...</I18Text>
                                )}
                            </View>
                        )}
                    />
                    {reachedEnd ? (
                        <></>
                    ) : (
                        <IconButtonRight
                            onPress={() => scrollRight()}
                            style={[
                                sx({
                                    width: [0, 30],
                                    display: ['none', 'flex'],
                                    height: [55, 55, 65, 50],
                                }),
                            ]}
                        >
                            <Gradient
                                start={{ x: 0, y: 1 }}
                                end={{ x: 1, y: 1 }}
                                colors={['#99999910', '#657c97']}
                                style={sx({
                                    flex: 1,
                                    padding: [0, 1],
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: 35,
                                    borderRadius: 5,
                                })}
                            >
                                <ScrollRightIcon
                                    name="leftIcon"
                                    fill={['white']}
                                    style={[sx({ width: [0, 30], display: ['none', 'flex'] })]}
                                />
                            </Gradient>
                        </IconButtonRight>
                    )}
                </View>
            </View>
        </View>
    );
}

export default SubscriptionPackages;
