import React, { useMemo, useState } from 'react';
import { Row, styled, Text, useDripsyTheme, useSx, View } from 'dripsy';
import { Icon } from 'app/components/icon/icon';
import { MotiPressable } from 'moti/interactions';
import { useCurrentRoute } from 'app/redux/hooks/use-current-route';
import { useRouter } from 'solito/router';
import ViewItems from './view-items';
import { Button } from 'app/components/button/common-button';
import { I18Text } from 'app/provider/i18-provider';

const StyledRow = styled(Row)({
  marginBottom: 2,
});

function SubscriptionCard({
  features,
  loading,
  currentActive,
  setProgressValue,
  index,
  user,
  continueUrl,
}: {
  loading?: boolean;
  features: any;
  active?: boolean;
  preference?: any;
  currentActive?: boolean;
  setProgressValue?: any;
  index?: number;
  user?: any;
  continueUrl?: string;
}) {
  const sx = useSx();
  const currentRoute = useCurrentRoute();
  const [hover, setHovered] = useState(false);
  const { replace, push } = useRouter();
  const { theme } = useDripsyTheme();
  const shadowStyle = useMemo(() => {
    if (hover) {
      return {
        shadowColor: `${theme?.colors?.$primary}`,
        shadowOffset: {
          width: 0,
          height: 0,
        },
        shadowOpacity: 1,
        shadowRadius: 10,
        elevation: 0,
        transform: [{ scaleY: 1.02 }, { scaleX: 1.02 }],
      };
    } else {
      return {};
    }
  }, [hover]);

  const handleSubscribe = async (subscriptionId: any) => {
    if (user) {
      push('/client-checkout/[pageType]/[id]', `/client-checkout/subscription/${subscriptionId}`);
    } else {
      replace(
        `/account/signin${continueUrl ? `?continue=${continueUrl}` : `?continue=${currentRoute}`
        }`
      );
    }
  };

  const getIcon = (durationData) => {
    if (durationData >= 365) {
      return 'yearlySubsIcon';
    } else if (durationData <= 92 && durationData >= 90) {
      return 'quarterlySubsIcon';
    } else {
      return 'monthlySubsIcon';
    }
  };
  const _subscriptionCard = () => {
    return (
      <View style={{ justifyContent: 'center', alignItems: 'center' }}>
        <MotiPressable
          onHoverIn={() => setHovered(true)}
          onHoverOut={() => setHovered(false)}
        >
          <View
            style={[
              sx({
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                borderStyle: 'solid',
                borderColor: '$primary',
                borderWidth: 2,
                borderRadius: 15,
                paddingY: 2,
                paddingX: 3,
                marginX: 2,
                maxWidth: 300,
                ...shadowStyle,
              }),
            ]}
          >
            <Text
              numberOfLines={1}
              style={[
                sx({
                  fontSize: 22,
                  fontWeight: 'bold',
                  color: '$primary',
                  marginY: 3,
                }),
              ]}
            >
              {features.name}
            </Text>
            <View
              style={[
                sx({
                  borderRadius: 35,
                  width: [90, 100],
                  height: [90, 100],
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: 4,
                  marginTop: 3,
                }),
              ]}
            >
              <Icon
                name={getIcon(features.billingFrequency)}
                fill={['#F47979']}
                style={[
                  sx({
                    width: [110, 130],
                    height: [110, 130],
                    justifyContent: 'center',
                    display: 'flex',
                    alignItems: 'center',
                  }),
                ]}
              />
            </View>
            <View style={[sx({ maxWidth: 225, minHeight: 160 })]}>
              <StyledRow>
                <Icon
                  name="checkInSub"
                  fill={['#f5f2ff']}
                  style={[sx({ marginRight: [2, 3] })]}
                />
                <Text
                  numberOfLines={1}
                ><I18Text>Duration</I18Text> {`( ${features.billingFrequency} days )`}</Text>
              </StyledRow>
              <StyledRow>
                {features.typeDirect ? <ViewItems
                  title={'Notes'}
                  label={`Access to ${features?.contents?.length || 0} Notes`}
                  list={features?.contents}
                  icon={
                    <Icon
                      name="checkInSub"
                      fill={['#f5f2ff']}
                      style={[sx({ marginRight: [2, 3] })]}
                    />
                  }
                /> : features?.classrooms.map((item: any) => (item?.courseName)).join(',').length ? <ViewItems
                  title={'Course'}
                  label={'Course Access : ' + features?.classrooms.map((item: any) => (item?.courseName)).join('', '')}
                  list={[]}
                  icon={
                    <Icon
                      name="checkInSub"
                      fill={['#f5f2ff']}
                      style={[sx({ marginRight: [2, 3] })]}
                    />
                  }
                /> : <></>}
              </StyledRow>
              <StyledRow>
  {features.typeDirect ? (
    <ViewItems
      title={'Classrooms'}
      label={`Access to ${features?.classrooms?.length || 0} classrooms`}
      list={features?.classrooms}
      icon={
        <Icon
          name="checkInSub"
          fill={['#f5f2ff']}
          style={[sx({ marginRight: [2, 3] })]}
        />
      }
    />
  ) : features?.competitiveExams && features.competitiveExams.length > 0 ? (
    <ViewItems
      title={'Competitive Exams'}
      label={'Exam Access: ' + features?.competitiveExams.map((item: any) => (item?.name)).join(', ')}
      list={features?.competitiveExams}
      icon={
        <Icon
          name="checkInSub"
          fill={['#f5f2ff']}
          style={[sx({ marginRight: [2, 3] })]}
        />
      }
    />
  ) : null}
</StyledRow>
<StyledRow>
  {features.classrooms.length > 0 && features?.classrooms?.some(item => item.specializationName) && (
    <ViewItems
      title={'Specialization'}
      label={'Specialization Access: ' + features?.classrooms.map((item: any) => (item?.specializationName)).join(', ')}
      list={[]}
      icon={
        <Icon
          name="checkInSub"
          fill={['#f5f2ff']}
          style={[sx({ marginRight: [2, 3] })]}
        />
      }
    />
  )}
</StyledRow>



              <StyledRow>
                {features.typeDirect ? <ViewItems
                  label={`Access to ${features?.exams?.length || 0} exams`}
                  title={'Exams'}
                  list={features?.exams}
                  icon={
                    <Icon
                      name="checkInSub"
                      fill={['#f5f2ff']}
                      style={[sx({ marginRight: [2, 3] })]}
                    />
                  }
                /> : <></>}
              </StyledRow>
              <StyledRow>
              </StyledRow>
            </View>
            <View
              style={[
                sx({
                  alignItems: 'baseline',
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 2,
                  marginBottom: 3,
                }),
              ]}
            >
              <Text
                style={sx({
                  fontWeight: 'bold',
                  fontSize: 18,
                  color: '$primary',
                })}
              >
                ₹
              </Text>
              <Text
                style={[
                  sx({
                    fontSize: 25,
                    color: '$primary',
                    fontWeight: 'bold',
                  }),
                ]}
              >
                {features?.price}
              </Text>
            </View>
            <View>
              <Button
                style={[
                  sx({
                    width: [220, 250],
                    height: 40,

                  }),
                ]}
                {...(currentActive
                  ? {
                    loading: loading,
                  }
                  : {})}
                onPress={() => {
                  handleSubscribe(features._id);
                  setProgressValue(index);
                }}
                disabled={features.alreadyPurchased ? true : false}
              >
                {features.alreadyPurchased ? 'Subscribed' : 'Subscribe Now'}
              </Button>
            </View>
          </View>
        </MotiPressable>
      </View>
    );
  };

  return _subscriptionCard();
}

export default SubscriptionCard;
